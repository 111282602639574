import React from 'react'
import PropTypes from 'prop-types'
import alertify from 'alertifyjs'

import Loader from './Loader'
import { loadBannerMessage, saveBannerMessage } from '../actions/SettingActions'

class StatusBanner extends React.Component {
  static propTypes = {
    params: PropTypes.shape({ id: PropTypes.string })
  }

  constructor(props) {
    super(props)
    this.state = { originalBannerMessage: '', bannerMessage: '', isLoading: false }
  }

  componentDidMount() {
    this.fetchBannerMessage()
  }

  fetchBannerMessage = () => {
    this.setState({ isLoading: true })
    loadBannerMessage((statusCode, body) => {
      this.setState({ isLoading: false })
      if (statusCode === 200) {
        const bannerMessage = (body && body.banner_message) || ''
        this.setState({ bannerMessage, originalBannerMessage: bannerMessage })
      }
    })
  }

  handleBannerMessageChange = ev => {
    this.setState({ bannerMessage: ev.target.value })
  }

  handleSaveBannerMessage = bannerMessage => {
    this.setState({ isLoading: true })
    saveBannerMessage(bannerMessage, (statusCode, body) => {
      this.setState({ isLoading: false })
      if (statusCode === 200) {
        this.setState({ bannerMessage, originalBannerMessage: bannerMessage })
      } else {
        return alertify.error((body && body.error) || 'Error saving banner message.')
      }
    })
  }

  buttonProps = () => {
    const { originalBannerMessage, bannerMessage } = this.state

    if (!originalBannerMessage || !originalBannerMessage.trim()) {
      const disabledProps = { className: 'button', children: 'Save', disabled: true }
      if (!bannerMessage || !bannerMessage.trim()) return disabledProps
    } else {
      const deleteProps = {
        className: 'button button-delete',
        children: 'Remove',
        disabled: false,
        onClick: () => this.handleSaveBannerMessage('')
      }
      if (!bannerMessage || !bannerMessage.trim()) return deleteProps
      if (originalBannerMessage === bannerMessage) return deleteProps
    }

    return {
      className: 'button',
      children: 'Save',
      disabled: false,
      onClick: () => this.handleSaveBannerMessage(bannerMessage)
    }
  }

  render() {
    const { bannerMessage, isLoading } = this.state

    return (
      <div className="admin-user-settings">
        <Loader isLoading={isLoading} />
        <div>
          <div className="header-items">
            <h1>System Status Banner</h1>
          </div>

          <div className="banner-container">
            <input
              type="text"
              value={bannerMessage}
              onChange={this.handleBannerMessageChange}
              placeholder="Enter banner message"
            />
            <button {...this.buttonProps()} />
          </div>
        </div>
      </div>
    )
  }
}

export default StatusBanner
