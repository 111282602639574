import React from 'react'

import { saveHotel } from '../actions/HotelActions'
import { loadIntegratedPlatforms } from '../actions/HotelPlatformActions'

class HotelDefaultPlatform extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      updating: false,
      defaultPlatform: props.hotel && props.hotel.get('default_platform'),
      platforms: null
    }
  }

  componentWillReceiveProps(nextProps) {
    const { hotel } = nextProps
    if (this.props.hotel.get('default_platform') !== hotel && hotel.get('default_platform')) {
      this.setState({ defaultPlatform: hotel && hotel.get('default_platform') })
    }
  }

  componentDidMount() {
    const { dispatch, hotel } = this.props
    dispatch(
      loadIntegratedPlatforms(hotel.get('id'), (statusCode, body) => {
        this.setState({ platforms: body })
      })
    )
  }

  selectDefaultPlatform = newPlatform => {
    const { dispatch, hotel } = this.props
    this.setState({ updating: true, error: null })
    const hotelData = { id: hotel.get('id'), default_platform: newPlatform }
    dispatch(
      saveHotel(null, hotelData, (statusCode, body) => {
        this.setState({ updating: false })
        if (statusCode === 200) {
          return this.setState({ defaultPlatform: newPlatform })
        } else {
          return this.setState({
            error: (body && body.error) || 'Error updating default platform.'
          })
        }
      })
    )
  }

  render() {
    const { hotel } = this.props
    const { updating, error, platforms } = this.state

    if (!platforms) return null

    const smsEnabled = platforms.sms && Object.values(platforms.sms).some(value => value.length > 0)
    const whatsappEnabled = platforms.message_bird && platforms.message_bird.length > 0

    if (!smsEnabled && !whatsappEnabled) return null

    const defaultPlatform = hotel && hotel.get('default_platform')
    return (
      <div className="section tmargin0-5em">
        <h3 className="tmargin0em">Default Platform for PMS updates</h3>
        <div className="pms-update-default-platform">
          <span>
            <input
              type="radio"
              name="platform"
              value="whatsapp"
              checked={defaultPlatform === 'whatsapp'}
              onChange={() => this.selectDefaultPlatform('whatsapp')}
              disabled={updating || !whatsappEnabled}
            />{' '}
            WhatsApp
          </span>
          <span>
            <input
              type="radio"
              name="platform"
              value="sms"
              checked={defaultPlatform === 'sms'}
              onChange={() => this.selectDefaultPlatform('sms')}
              disabled={updating || !smsEnabled}
            />{' '}
            SMS
          </span>
        </div>
        {error && <div className="error tmargin1em">{error}</div>}
      </div>
    )
  }
}

export default HotelDefaultPlatform
