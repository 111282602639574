/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import React from 'react'
import PropTypes from 'prop-types'
import { fromJS, is, Map } from 'immutable'
import { connect } from 'react-redux'
import Select from 'react-select'
import alertify from 'alertifyjs'
import uuid from 'node-uuid'

import Loader from './Loader'
import {
  loadReplyTemplates,
  createReplyTemplate,
  deleteReplyTemplate,
  addReplyTemplateToAllHotels,
  updateReplyTemplate,
  uploadImageToMessageBird
} from '../actions/HotelActions'
import HotelUtils from '../utils/HotelUtils'
import ReplyTemplateCategories from '../constants/ReplyTemplateCategories'
import EngagementTemplateStatuses from '../constants/ReplyTemplateStatuses'
import Spinner from './Spinner'

const { IN_REVIEW, APPROVED, REJECTED } = EngagementTemplateStatuses
const STATUS_CREATED = 201

class EditWeChatReplyTemplate extends React.Component {
  static propTypes = {
    weChatReplyTemplate: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      weChatReplyTemplate: this.getTemplateProps(props),
      templateCategories: new Map(ReplyTemplateCategories),
      languageOptions: HotelUtils.getLanguagesList(),
      addToAllHotels: false,
      isUploadingAttachment: false,
      isConsentTemplate: false
    }
  }

  componentDidMount() {
    const { dispatch, match, weChatReplyTemplates } = this.props
    const { weChatTemplateId, selectedHotelId } = match && match.params
    const { weChatReplyTemplate } = this.state

    if (weChatTemplateId && weChatReplyTemplates.isEmpty()) {
      this.setState({ isLoading: true })
      dispatch(
        loadReplyTemplates(selectedHotelId, 'we_chat', () => {
          this.setState({ isLoading: false })
        })
      )
    }
  }

  componentWillReceiveProps(nextProps) {
    const { match } = nextProps
    const { weChatTemplateId } = match && match.params
    if (weChatTemplateId) {
      // Not needed in case of new template creation
      this.setState({
        weChatReplyTemplate: this.getTemplateProps(nextProps)
      })
    }
  }

  getTemplateProps = props => {
    const { match, weChatReplyTemplate } = props
    const { weChatTemplateId } = match && match.params
    return weChatTemplateId ? this.findReplyTemplate(props) : weChatReplyTemplate
  }

  findReplyTemplate = props => {
    const { match, weChatReplyTemplates } = props
    const { weChatTemplateId } = match && match.params
    let template = weChatReplyTemplates.get(weChatTemplateId)
    if (template && template.get('template_id') === 'N/A' && template.get('name') === 'N/A') {
      template = template.set('template_id', '').set('name', '')
    }
    return template
  }

  handleChange = ev => {
    let inputVal = ev.target.value
    if (ev.target.name === 'dummy_template') {
      const openingCurlyTags = ((inputVal && inputVal.match(/\{{/g)) || '').length
      const closingCurlyTags = ((inputVal && inputVal.match(/\}}/g)) || '').length
      this.setState({ messageFormatError: openingCurlyTags !== closingCurlyTags })
    } else if (ev.target.name === 'we_chat_template_name') {
      inputVal = inputVal.toLowerCase().replace(/[^a-zA-Z1-9_]/g, '')
    }

    this.setState({
      weChatReplyTemplate: this.state.weChatReplyTemplate.set(ev.target.name, inputVal)
    })
  }

  handleSelectChange = (option, path) => {
    this.setState({
      weChatReplyTemplate: this.state.weChatReplyTemplate.set(path, option && option.value)
    })
  }

  handleSubmit = ev => {
    ev && ev.preventDefault()

    const { dispatch, match } = this.props
    const { selectedHotelId } = match && match.params
    const { weChatReplyTemplate, addToAllHotels } = this.state

    this.setState({ isSaving: true })
    dispatch(
      createReplyTemplate(
        selectedHotelId,
        weChatReplyTemplate.toJS(),
        'we_chat',
        (statusCode, body) => {
          this.setState({ isSaving: false })
          if (statusCode !== 201) {
            return alertify.error((body && body.error) || 'Error creating template.')
          }

          addToAllHotels &&
            dispatch(
              addReplyTemplateToAllHotels(
                body.we_chat_engagement_template.id,
                'we_chat',
                (statusCode, body) => {
                  if (statusCode !== 201) {
                    return alertify.error(
                      (body && body.error) ||
                        'Error adding template to all hotels at account level.'
                    )
                  }
                }
              )
            )

          alertify.success('Template successfully created.')
          this.handleCancel()
        }
      )
    )
  }

  handleUpdate = (ev, action) => {
    ev && ev.preventDefault()
    action === APPROVED && this.setState({ isApproving: true })
    action === REJECTED && this.setState({ isRejecting: true })

    const { dispatch, match, history } = this.props
    const { selectedHotelId, selectedAccountId } = match && match.params
    const { weChatReplyTemplate } = this.state

    let updatedTemplate = weChatReplyTemplate.set('status', action)

    updatedTemplate =
      action === REJECTED ? this.updateRejectedTemplated(updatedTemplate) : updatedTemplate

    dispatch(
      updateReplyTemplate(
        updatedTemplate.get('id'),
        updatedTemplate.toJS(),
        'we_chat',
        (statusCode, body) => {
          action === APPROVED && this.setState({ isApproving: false })
          action === REJECTED && this.setState({ isRejecting: false })
          if (statusCode !== 204) {
            return alertify.error(
              (body && body.error) ||
                `Error ${action === REJECTED ? 'rejecting' : 'approving'} template.`
            )
          }
          alertify.success(`Template successfully ${action === REJECTED ? 'rejected' : 'approved'}`)
          history.push(`/account/${selectedAccountId}/hotel/${selectedHotelId}/reply-templates`)
        }
      )
    )
  }

  updateRejectedTemplated = template => {
    return template.set('template_namespace', 'N/A').set('we_chat_template_name', 'N/A')
  }

  handleCancel = ev => {
    ev && ev.preventDefault()

    const { match, history } = this.props
    const { selectedAccountId, selectedHotelId } = match && match.params
    history.push(`/account/${selectedAccountId}/hotel/${selectedHotelId}/reply-templates`)
  }

  handleDelete = ev => {
    ev && ev.preventDefault()

    const { dispatch } = this.props
    const { weChatReplyTemplate } = this.state

    this.setState({ isDeleting: true })
    dispatch(
      deleteReplyTemplate(weChatReplyTemplate.get('id'), 'we_chat', (statusCode, body) => {
        this.setState({ isDeleting: false })
        if (statusCode !== 204) {
          return alertify.error((body && body.error) || 'Error deleting template.')
        }

        alertify.success('Template successfully deleted.')
        this.handleCancel()
      })
    )
  }

  handleCheckBoxClick = () => this.setState({ addToAllHotels: !this.state.addToAllHotels })

  handleCheckBoxConsentTemplate = () =>
    this.setState({
      isConsentTemplate: !this.state.isConsentTemplate,
      weChatReplyTemplate: this.state.weChatReplyTemplate.set(
        'consent_template',
        !this.state.isConsentTemplate
      )
    })

  isPristine = () => {
    const {
      weChatReplyTemplate,
      messageFormatError,
      isSaving,
      isApproving,
      isRejecting
    } = this.state

    return (
      !weChatReplyTemplate.get('name') ||
      !weChatReplyTemplate.get('template_id') ||
      !weChatReplyTemplate.get('language') ||
      isSaving ||
      isApproving ||
      isRejecting ||
      messageFormatError
    )
  }

  removeFilesAttachment = e => {
    e.preventDefault()
    this.setState({
      weChatReplyTemplate: this.state.weChatReplyTemplate.set('media_url', '')
    })
  }

  render() {
    const { match } = this.props
    const { weChatTemplateId } = match && match.params
    const {
      templateCategories,
      languageOptions,
      weChatReplyTemplate,
      isLoading,
      isSaving,
      isDeleting,
      isApproving,
      isRejecting,
      messageFormatError,
      addToAllHotels
    } = this.state

    const mediaUrl = weChatReplyTemplate && weChatReplyTemplate.get('media_url')

    const meppedTemplateCategories = fromJS(
      templateCategories.map((value, key) => {
        return { value: key, label: value }
      })
    )
    if (isLoading) {
      return (
        <div className="settings-container">
          <Loader isLoading={isLoading} />
        </div>
      )
    }

    if (!weChatReplyTemplate) {
      return <div className="settings-container">Template not found.</div>
    }

    return (
      <form className="settings-container reply-templates-settings" autoComplete="off">
        <h2>{weChatTemplateId ? '' : 'Create'} WeChat Engagement Template </h2>

        <h3>
          <input
            type="text"
            placeholder="Name this template"
            name="name"
            onChange={this.handleChange}
            value={weChatReplyTemplate.get('name')}
            required
          />
        </h3>

        <fieldset>
          <legend>Template ID</legend>
          <input
            type="text"
            name="template_id"
            placeholder="To be obtained from WeChat"
            onChange={this.handleChange}
            value={weChatReplyTemplate.get('template_id')}
            required
          />
        </fieldset>

        <fieldset>
          <legend>Language</legend>
          <Select
            value={weChatReplyTemplate.get('language')}
            options={languageOptions.toJS()}
            onChange={option => this.handleSelectChange(option, 'language')}
            clearable={false}
          />
        </fieldset>

        <fieldset>
          <legend>Message</legend>
          <textarea
            placeholder="Enter template content here"
            name="dummy_template"
            onChange={this.handleChange}
            value={weChatReplyTemplate.get('dummy_template')}
            required
          />
        </fieldset>

        <fieldset>
          <legend>Fields Names</legend>
          <input
            type="text"
            name="fields_names"
            placeholder="To be obtained from WeChat"
            onChange={this.handleChange}
            value={weChatReplyTemplate.get('fields_names') || ''}
            required
          />
        </fieldset>

        <fieldset className="preview-screen">
          <div>
            <span>Preview: </span>
            <span className="render-error-message">
              {messageFormatError &&
                'At least one of the field variables in the message above is entered incorrectly. Please double-check the format, remembering the need for double braces.'}
            </span>
            <blockquote className={messageFormatError ? 'render-error' : ''}>
              {mediaUrl && <img src={mediaUrl} width="50" height="50" />}
              <pre className="text">{weChatReplyTemplate.get('dummy_template')}</pre>
            </blockquote>
          </div>
        </fieldset>

        {!weChatTemplateId && (
          <fieldset className="form-item">
            <div>
              <div
                className={`select-field ${addToAllHotels ? 'selected' : ''}`}
                onClick={() => this.handleCheckBoxClick()}
              >
                {addToAllHotels && <img src={`/icons/select-tick.svg`} alt="" />}
              </div>
              <div>Add to all hotels at account level</div>
            </div>
          </fieldset>
        )}

        <fieldset className="tmargin2em">
          {!weChatTemplateId ? (
            <button
              disabled={this.isPristine()}
              type="button"
              className="button"
              onClick={this.handleSubmit}
            >
              {isSaving ? 'Creating...' : 'Create'}
            </button>
          ) : weChatReplyTemplate.get('status') !== IN_REVIEW ? (
            <button type="button" className="button button-delete" onClick={this.handleDelete}>
              {isDeleting ? 'Deleting...' : 'Delete'}
            </button>
          ) : (
            <div style={{ float: 'right' }}>
              <button
                type="button"
                className="button button-delete"
                style={{ marginRight: '10px' }}
                onClick={e => this.handleUpdate(e, REJECTED)}
              >
                {isRejecting ? 'Rejecting...' : 'Reject'}
              </button>
              <button
                disabled={this.isPristine()}
                type="button"
                className="button"
                onClick={e => this.handleUpdate(e, APPROVED)}
              >
                {isApproving ? 'Approving...' : 'Approve'}
              </button>
            </div>
          )}
          <button type="cancel" className="button" onClick={this.handleCancel}>
            Cancel
          </button>
        </fieldset>
      </form>
    )
  }
}

const mapStateToProps = state => ({
  weChatReplyTemplates: state.weChatReplyTemplates,
  weChatReplyTemplate: state.weChatReplyTemplate
})

export default connect(mapStateToProps)(EditWeChatReplyTemplate)
