import { fromJS, OrderedMap } from 'immutable'

import ActionTypes from '../constants/ActionTypes'

const {
  RECEIVE_GET_HOTEL_LINE_REPLY_TEMPLATES,
  RECEIVE_GET_HOTEL_LINE_TEMPLATE,
  RECEIVE_DELETE_HOTEL_LINE_TEMPLATE
} = ActionTypes

const initialState = new OrderedMap()

export default (state = initialState, action) => {
  const { type, data, lineTemplateId } = action

  switch (type) {
    case RECEIVE_GET_HOTEL_LINE_REPLY_TEMPLATES:
      return new OrderedMap(
        data.line_engagement_templates.map(template => [template.id, fromJS(template)])
      )

    case RECEIVE_GET_HOTEL_LINE_TEMPLATE:
      return state.set(data.line_engagement_template.id, fromJS(data.line_engagement_template))

    case RECEIVE_DELETE_HOTEL_LINE_TEMPLATE:
      return state.remove(lineTemplateId)

    default:
      return state
  }
}
