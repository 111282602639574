/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import React from 'react'
import PropTypes from 'prop-types'
import { fromJS, is, Map } from 'immutable'
import { connect } from 'react-redux'
import Select from 'react-select'
import alertify from 'alertifyjs'
import uuid from 'node-uuid'

import Loader from './Loader'
import {
  loadReplyTemplates,
  createReplyTemplate,
  deleteReplyTemplate,
  addReplyTemplateToAllHotels,
  updateReplyTemplate,
  uploadImageToMessageBird
} from '../actions/HotelActions'
import HotelUtils from '../utils/HotelUtils'
import ReplyTemplateCategories from '../constants/ReplyTemplateCategories'
import EngagementTemplateStatuses from '../constants/ReplyTemplateStatuses'
import Spinner from './Spinner'

const { IN_REVIEW, APPROVED, REJECTED } = EngagementTemplateStatuses
const STATUS_CREATED = 201

class EditlineReplyTemplate extends React.Component {
  static propTypes = {
    lineReplyTemplate: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      lineReplyTemplate: this.getTemplateProps(props),
      templateCategories: new Map(ReplyTemplateCategories),
      languageOptions: HotelUtils.getLanguagesList(),
      addToAllHotels: false,
      isUploadingAttachment: false,
      isConsentTemplate: false,
      parsedTemplateContent: ''
    }
  }

  componentDidMount() {
    const { dispatch, match, lineReplyTemplates } = this.props
    const { lineTemplateId, selectedHotelId } = match && match.params
    const { lineReplyTemplate } = this.state

    if (lineTemplateId && lineReplyTemplates.isEmpty()) {
      this.setState({ isLoading: true })
      dispatch(
        loadReplyTemplates(selectedHotelId, 'line', () => {
          this.setState({ isLoading: false })
        })
      )
    }
  }

  componentWillReceiveProps(nextProps) {
    const { match } = nextProps
    const { lineTemplateId } = match && match.params
    if (lineTemplateId) {
      // Not needed in case of new template creation
      this.setState({
        lineReplyTemplate: this.getTemplateProps(nextProps)
      })
    }
  }

  getTemplateProps = props => {
    const { match, lineReplyTemplate } = props
    const { lineTemplateId } = match && match.params
    return lineTemplateId ? this.findReplyTemplate(props) : lineReplyTemplate
  }

  findReplyTemplate = props => {
    const { match, lineReplyTemplates } = props
    const { lineTemplateId } = match && match.params
    let template = lineReplyTemplates.get(lineTemplateId)
    if (template && template.get('template_id') === 'N/A' && template.get('name') === 'N/A') {
      template = template.set('template_id', '').set('name', '')
    }
    return template
  }

  handleChange = ev => {
    let inputVal = ev.target.value
    if (ev.target.name === 'template_id') {
      this.parseAndSetTemplateContent(inputVal)
    } else if (ev.target.name === 'line_template_name') {
      inputVal = inputVal.toLowerCase().replace(/[^a-zA-Z1-9_]/g, '')
    }

    this.setState({
      lineReplyTemplate: this.state.lineReplyTemplate.set(ev.target.name, inputVal)
    })
  }

  parseAndSetTemplateContent = templateId => {
    let parsedContent = templateId
    try {
      const templateObject = typeof templateId === 'string' ? JSON.parse(templateId) : templateId
      if (
        templateObject &&
        templateObject.body &&
        templateObject.body.contents &&
        templateObject.body.contents[0] &&
        templateObject.body.contents[0].text
      ) {
        parsedContent = templateObject.body.contents[0].text
      } else {
        console.error('Template structure not as expected')
      }
    } catch (error) {
      console.error('Error parsing templateId: ', error.message)
    }
    this.setState({
      parsedTemplateContent: parsedContent,
      lineReplyTemplate: this.state.lineReplyTemplate
        .set('template_id', templateId)
        .set('dummy_template', parsedContent)
    })
  }

  handleSelectChange = (option, path) => {
    this.setState({
      lineReplyTemplate: this.state.repllineReplyTemplateyTemplate.set(path, option && option.value)
    })
  }

  handleSubmit = ev => {
    ev && ev.preventDefault()

    const { dispatch, match } = this.props
    const { selectedHotelId } = match && match.params
    const { lineReplyTemplate, addToAllHotels, parsedTemplateContent } = this.state
    const templateToSubmit = lineReplyTemplate.set('dummy_template', parsedTemplateContent)
    console.log('templateToSubmit: ', templateToSubmit)

    this.setState({ isSaving: true })
    dispatch(
      createReplyTemplate(selectedHotelId, templateToSubmit.toJS(), 'line', (statusCode, body) => {
        this.setState({ isSaving: false })
        if (statusCode !== 201) {
          return alertify.error((body && body.error) || 'Error creating template.')
        }

        addToAllHotels &&
          dispatch(
            addReplyTemplateToAllHotels(
              body.line_engagement_template.id,
              'line',
              (statusCode, body) => {
                if (statusCode !== 201) {
                  return alertify.error(
                    (body && body.error) || 'Error adding template to all hotels at account level.'
                  )
                }
              }
            )
          )

        alertify.success('Template successfully created.')
        this.handleCancel()
      })
    )
  }

  handleUpdate = (ev, action) => {
    ev && ev.preventDefault()
    action === APPROVED && this.setState({ isApproving: true })
    action === REJECTED && this.setState({ isRejecting: true })

    const { dispatch, match, history } = this.props
    const { selectedHotelId, selectedAccountId } = match && match.params
    const { lineReplyTemplate } = this.state

    let updatedTemplate = lineReplyTemplate.set('status', action)

    updatedTemplate =
      action === REJECTED ? this.updateRejectedTemplated(updatedTemplate) : updatedTemplate

    dispatch(
      updateReplyTemplate(
        updatedTemplate.get('id'),
        updatedTemplate.toJS(),
        'line',
        (statusCode, body) => {
          action === APPROVED && this.setState({ isApproving: false })
          action === REJECTED && this.setState({ isRejecting: false })
          if (statusCode !== 204) {
            return alertify.error(
              (body && body.error) ||
                `Error ${action === REJECTED ? 'rejecting' : 'approving'} template.`
            )
          }
          alertify.success(`Template successfully ${action === REJECTED ? 'rejected' : 'approved'}`)
          history.push(`/account/${selectedAccountId}/hotel/${selectedHotelId}/reply-templates`)
        }
      )
    )
  }

  updateRejectedTemplated = template => {
    return template.set('template_namespace', 'N/A').set('line_template_name', 'N/A')
  }

  handleCancel = ev => {
    ev && ev.preventDefault()

    const { match, history } = this.props
    const { selectedAccountId, selectedHotelId } = match && match.params
    history.push(`/account/${selectedAccountId}/hotel/${selectedHotelId}/reply-templates`)
  }

  handleDelete = ev => {
    ev && ev.preventDefault()

    const { dispatch } = this.props
    const { lineReplyTemplate } = this.state

    this.setState({ isDeleting: true })
    dispatch(
      deleteReplyTemplate(lineReplyTemplate.get('id'), 'line', (statusCode, body) => {
        this.setState({ isDeleting: false })
        if (statusCode !== 204) {
          return alertify.error((body && body.error) || 'Error deleting template.')
        }

        alertify.success('Template successfully deleted.')
        this.handleCancel()
      })
    )
  }

  handleCheckBoxClick = () => this.setState({ addToAllHotels: !this.state.addToAllHotels })

  handleCheckBoxConsentTemplate = () =>
    this.setState({
      isConsentTemplate: !this.state.isConsentTemplate,
      lineReplyTemplate: this.state.lineReplyTemplate.set(
        'consent_template',
        !this.state.isConsentTemplate
      )
    })

  isPristine = () => {
    const { lineReplyTemplate, messageFormatError, isSaving, isApproving, isRejecting } = this.state

    return (
      !lineReplyTemplate.get('name') ||
      !lineReplyTemplate.get('template_id') ||
      !lineReplyTemplate.get('language') ||
      isSaving ||
      isApproving ||
      isRejecting ||
      messageFormatError
    )
  }

  removeFilesAttachment = e => {
    e.preventDefault()
    this.setState({
      lineReplyTemplate: this.state.lineReplyTemplate.set('media_url', '')
    })
  }

  render() {
    const { match } = this.props
    const { lineTemplateId } = match && match.params
    const {
      templateCategories,
      languageOptions,
      lineReplyTemplate,
      isLoading,
      isSaving,
      isDeleting,
      isApproving,
      isRejecting,
      messageFormatError,
      addToAllHotels
    } = this.state

    const mediaUrl = lineReplyTemplate && lineReplyTemplate.get('media_url')

    const meppedTemplateCategories = fromJS(
      templateCategories.map((value, key) => {
        return { value: key, label: value }
      })
    )
    if (isLoading) {
      return (
        <div className="settings-container">
          <Loader isLoading={isLoading} />
        </div>
      )
    }

    if (!lineReplyTemplate) {
      return <div className="settings-container">Template not found.</div>
    }

    return (
      <form className="settings-container reply-templates-settings" autoComplete="off">
        <h2>{lineTemplateId ? '' : 'Create'} line Engagement Template </h2>

        <h3>
          <input
            type="text"
            placeholder="Name this template"
            name="name"
            onChange={this.handleChange}
            value={lineReplyTemplate.get('name')}
            required
          />
        </h3>

        <fieldset>
          <legend>Template JSON</legend>
          <input
            type="text"
            name="template_id"
            placeholder="To be obtained from Line flex-simulator"
            onChange={this.handleChange}
            value={lineReplyTemplate.get('template_id')}
            required
          />
        </fieldset>

        <fieldset>
          <legend>Language</legend>
          <Select
            value={lineReplyTemplate.get('language')}
            options={languageOptions.toJS()}
            onChange={option => this.handleSelectChange(option, 'language')}
            clearable={false}
          />
        </fieldset>

        <fieldset>
          <legend>Message</legend>
          <textarea
            name="dummy_template"
            onChange={this.handleChange}
            value={this.state.parsedTemplateContent}
            readOnly
            required
          />
        </fieldset>

        <fieldset className="preview-screen">
          <div>
            <span>Preview: </span>
            <span className="render-error-message">
              {messageFormatError &&
                'At least one of the field variables in the message above is entered incorrectly. Please double-check the format, remembering the need for double braces.'}
            </span>
            <blockquote className={messageFormatError ? 'render-error' : ''}>
              {mediaUrl && <img src={mediaUrl} width="50" height="50" />}
              <pre className="text">
                {(() => {
                  return this.state.parsedTemplateContent
                })()}
              </pre>
            </blockquote>
          </div>
        </fieldset>

        {!lineTemplateId && (
          <fieldset className="form-item">
            <div>
              <div
                className={`select-field ${addToAllHotels ? 'selected' : ''}`}
                onClick={() => this.handleCheckBoxClick()}
              >
                {addToAllHotels && <img src={`/icons/select-tick.svg`} alt="" />}
              </div>
              <div>Add to all hotels at account level</div>
            </div>
          </fieldset>
        )}

        <fieldset className="tmargin2em">
          {!lineTemplateId ? (
            <button
              disabled={this.isPristine()}
              type="button"
              className="button"
              onClick={this.handleSubmit}
            >
              {isSaving ? 'Creating...' : 'Create'}
            </button>
          ) : lineReplyTemplate.get('status') !== IN_REVIEW ? (
            <button type="button" className="button button-delete" onClick={this.handleDelete}>
              {isDeleting ? 'Deleting...' : 'Delete'}
            </button>
          ) : (
            <div style={{ float: 'right' }}>
              <button
                type="button"
                className="button button-delete"
                style={{ marginRight: '10px' }}
                onClick={e => this.handleUpdate(e, REJECTED)}
              >
                {isRejecting ? 'Rejecting...' : 'Reject'}
              </button>
              <button
                disabled={this.isPristine()}
                type="button"
                className="button"
                onClick={e => this.handleUpdate(e, APPROVED)}
              >
                {isApproving ? 'Approving...' : 'Approve'}
              </button>
            </div>
          )}
          <button type="cancel" className="button" onClick={this.handleCancel}>
            Cancel
          </button>
        </fieldset>
      </form>
    )
  }
}

const mapStateToProps = state => ({
  lineReplyTemplates: state.lineReplyTemplates,
  lineReplyTemplate: state.lineReplyTemplate
})

export default connect(mapStateToProps)(EditlineReplyTemplate)
