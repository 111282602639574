/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2019
 */

import { fromJS, OrderedMap } from 'immutable'

import ActionTypes from '../constants/ActionTypes'

const {
  RECEIVE_GET_HOTEL_WHATSAPP_REPLY_TEMPLATES,
  RECEIVE_GET_HOTEL_WHATSAPP_REPLY_TEMPLATE,
  RECEIVE_DELETE_HOTEL_WHATSAPP_REPLY_TEMPLATE
} = ActionTypes

const initialState = new OrderedMap()

export default (state = initialState, action) => {
  const { type, data, whatsAppTemplateId } = action

  switch (type) {
    case RECEIVE_GET_HOTEL_WHATSAPP_REPLY_TEMPLATES:
      return new OrderedMap(
        data.whats_app_engagement_templates.map(template => [template.id, fromJS(template)])
      )

    case RECEIVE_GET_HOTEL_WHATSAPP_REPLY_TEMPLATE:
      return state.set(
        data.whats_app_engagement_template.id,
        fromJS(data.whats_app_engagement_template)
      )

    case RECEIVE_DELETE_HOTEL_WHATSAPP_REPLY_TEMPLATE:
      return state.remove(whatsAppTemplateId)

    default:
      return state
  }
}
