/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2018
 */

import ApiUtils from '../utils/ApiUtils'
import ActionTypes from '../constants/ActionTypes'

const {
  RECEIVE_GET_ADMIN_USERS,
  RECEIVE_GET_ADMIN_USER,
  RECEIVE_DELETE_ADMIN_USER,
  RECEIVE_UPDATE_USER_ROLE,
  RECEIVE_UPDATE_USER_PASSWORD
} = ActionTypes

export function loadUsers(page, length, cb) {
  return dispatch => {
    const url = `/v1/admin/admin_users?page=${page}&length=${length}`
    ApiUtils.get(true, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_ADMIN_USERS,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function saveUser(admin, cb) {
  return dispatch => {
    const params = { admin_user: admin }
    const method = admin.id ? 'put' : 'post'
    const url = admin.id ? `/v1/admin/admin_users/${admin.id}` : `/v1/admin/admin_users`

    ApiUtils[method](params, url, (statusCode, body) => {
      if (statusCode === 200 || statusCode === 201) {
        dispatch({
          type: RECEIVE_GET_ADMIN_USER,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function deleteUser(userId, cb) {
  return dispatch => {
    const url = `/v1/admin/admin_users/${userId}`

    ApiUtils.delete(true, url, (statusCode, body) => {
      if (statusCode === 204) {
        dispatch({
          type: RECEIVE_DELETE_ADMIN_USER,
          userId: userId,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function updateUserRole(userId, data, cb) {
  return dispatch => {
    const params = { admin_user: data }
    const url = `/v1/admin/admin_users/${userId}/roles`

    ApiUtils.put(params, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_UPDATE_USER_ROLE,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function updateUserPassword(userId, data, cb) {
  return dispatch => {
    const params = { admin_user: data }
    const url = `/v1/admin/admin_users/${userId}/change_password`

    ApiUtils.post(params, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_UPDATE_USER_PASSWORD,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function resetUser2fa(userId, cb) {
  return dispatch => {
    const url = `/v1/admin/admin_users/${userId}/reset_2fa`

    ApiUtils.put(null, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_ADMIN_USER,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function saveBannerMessage(bannerMessage, cb) {
  ApiUtils.put({ banner_message: bannerMessage }, `/v1/admin/banner_messages`, cb)
}

export function loadBannerMessage(cb) {
  ApiUtils.get(null, `/v1/admin/banner_messages`, cb)
}
