import { Map } from 'immutable'

const initialState = Map({
  category: '',
  is_active: true,
  language: 'en',
  name: '',
  template_id: ''
})

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}
