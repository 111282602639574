/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, July 2018
 */

/* eslint no-mixed-operators: "off" */
/* eslint jsx-a11y/alt-text: "off" */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Hotel2faSettings from './Hotel2faSettings'
import HotelDefaultPlatform from './HotelDefaultPlatform'
import HotelFeatureFlags from './HotelFeatureFlags'

class HotelSettings extends React.Component {
  static propTypes = {
    hotel: PropTypes.object
  }

  render() {
    const { dispatch, match, hotels, featureFlags, platforms } = this.props
    const { selectedHotelId } = match && match.params

    const hotel = hotels && hotels.get(selectedHotelId)

    return (
      <div className="settings-container display-flex-column">
        <Hotel2faSettings dispatch={dispatch} hotel={hotel} />
        <HotelDefaultPlatform dispatch={dispatch} hotel={hotel} />
        <HotelFeatureFlags
          dispatch={dispatch}
          selectedHotelId={selectedHotelId}
          featureFlags={featureFlags}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  hotels: state.hotels,
  featureFlags: state.hotelSettings.get('featureFlags'),
  platforms: state.hotelPlatforms.get('platforms')
})

export default connect(mapStateToProps)(HotelSettings)
