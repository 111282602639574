/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2020
 */

import { fromJS, OrderedMap } from 'immutable'

import ActionTypes from '../constants/ActionTypes'

const { RECEIVE_PERMISSIONS, RECEIVE_PROPERTIES_PERMISSIONS } = ActionTypes

const initialState = new OrderedMap()

export default (state = initialState, action) => {
  const { type, data } = action

  switch (type) {
    case RECEIVE_PERMISSIONS:
      const permissions = new OrderedMap(
        data.permissions.map(permission => [permission.id, fromJS(permission)])
      )
      return state.merge(permissions)

    case RECEIVE_PROPERTIES_PERMISSIONS:
      return state

    default:
      return state
  }
}
