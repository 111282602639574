/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import ApiUtils from '../utils/ApiUtils'
import ActionTypes from '../constants/ActionTypes'

const {
  RECEIVE_GET_ACCOUNT,
  RECEIVE_DELETE_ACCOUNT,
  RECEIVE_GET_BUSINESS_TYPES,
  RECEIVE_GET_BUSINESS_TYPE,
  RECEIVE_GET_FEATURE_FLAGS,
  RECEIVE_PERMISSIONS,
  RECEIVE_PROPERTIES_PERMISSIONS
} = ActionTypes

export function saveAccount(account, cb) {
  return dispatch => {
    const params = { account }
    const method = account.id ? 'put' : 'post'
    const url = account.id ? `/v1/admin/accounts/${account.id}` : `/v1/admin/accounts`

    ApiUtils[method](params, url, (statusCode, body) => {
      if (statusCode === 200 || statusCode === 201) {
        dispatch({
          type: RECEIVE_GET_ACCOUNT,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function deleteAccount(accountId, cb) {
  return dispatch => {
    const url = `/v1/admin/accounts/${accountId}/delete`

    ApiUtils.delete(true, url, (statusCode, body) => {
      if (statusCode === 200 || statusCode === 201) {
        dispatch({
          type: RECEIVE_DELETE_ACCOUNT,
          accountId: accountId,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function loadBusinessTypes(accountId, cb) {
  return dispatch => {
    const url = `/v1/admin/business_types?page=1&length=100`
    ApiUtils.get(true, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_BUSINESS_TYPES,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

// @ Load list of all existing feature-flags across businesses/hotels
export function loadFeatureFlagsList(cb) {
  return dispatch => {
    const url = `/v1/admin/business_types/feature_flags`
    ApiUtils.get(true, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_FEATURE_FLAGS,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function loadPermissions(cb) {
  return dispatch => {
    const url = `/v1/admin/permissions`
    ApiUtils.get(true, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_PERMISSIONS,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function loadUserPermissions(userId, cb) {
  return dispatch => {
    const url = `/v1/admin/user_permissions/${userId}`
    ApiUtils.get(true, url, (statusCode, body) => {
      cb && cb(statusCode, body)
    })
  }
}

export function updateUserPermissions(params, cb) {
  return dispatch => {
    const url = `/v1/admin/user-permissions`

    ApiUtils.post(params, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_PERMISSIONS,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function loadUserPropertiesPermissions(userId, cb) {
  return dispatch => {
    const url = `/v1/admin/user_properties_permissions/${userId}`
    ApiUtils.get(true, url, (statusCode, body) => {
      cb && cb(statusCode, body)
    })
  }
}

export function updateUserPropertiesPermissions(params, userId, cb) {
  return dispatch => {
    const url = `/v1/admin/user_properties_permissions/${userId}`

    ApiUtils.put(params, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_PROPERTIES_PERMISSIONS,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function deleteUserPropertiesPermissions(userId, cb) {
  return dispatch => {
    const url = `/v1/admin/user_properties_permissions/${userId}`
    ApiUtils.delete(true, url, (statusCode, body) => {
      cb && cb(statusCode, body)
    })
  }
}

export function saveBusinessType(businessType, cb) {
  return dispatch => {
    const params = {
      business_type: businessType
    }

    const businessTypeId = businessType.id
    const method = businessTypeId ? 'put' : 'post'
    const url = `/v1/admin/business_types/${businessTypeId ? businessTypeId : ''}`

    ApiUtils[method](params, url, (statusCode, body) => {
      if (statusCode === 200 || statusCode === 201) {
        dispatch({
          type: RECEIVE_GET_BUSINESS_TYPE,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}
