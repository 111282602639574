import { fromJS, OrderedMap } from 'immutable'

import ActionTypes from '../constants/ActionTypes'

const {
  RECEIVE_GET_HOTEL_WECHAT_REPLY_TEMPLATES,
  RECEIVE_GET_HOTEL_WECHAT_REPLY_TEMPLATE,
  RECEIVE_DELETE_HOTEL_WECHAT_REPLY_TEMPLATE
} = ActionTypes

const initialState = new OrderedMap()

export default (state = initialState, action) => {
  const { type, data, weChatTemplateId } = action

  switch (type) {
    case RECEIVE_GET_HOTEL_WECHAT_REPLY_TEMPLATES:
      return new OrderedMap(
        data.we_chat_engagement_templates.map(template => [template.id, fromJS(template)])
      )

    case RECEIVE_GET_HOTEL_WECHAT_REPLY_TEMPLATE:
      return state.set(
        data.we_chat_engagement_template.id,
        fromJS(data.we_chat_engagement_template)
      )

    case RECEIVE_DELETE_HOTEL_WECHAT_REPLY_TEMPLATE:
      return state.remove(weChatTemplateId)

    default:
      return state
  }
}
