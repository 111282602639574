/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2018
 */

/* eslint no-mixed-operators: "off" */
/* eslint jsx-a11y/alt-text: "off" */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import alertify from 'alertifyjs'
import Select from 'react-select'
import _ from 'lodash'

import Loader from './Loader'
import HighlightSearchMatches from './HighlightSearchMatches'
import {
  loadHotelTeams,
  loadHotelStaff,
  updateHotelTeam,
  deleteHotelTeam,
  addHotelUserToTeam,
  removeHotelUserFromTeam
} from '../actions/HotelActions'
import HotelUtils from '../utils/HotelUtils'
import RegExpUtils from '../utils/RegExpUtils'

class TeamItem extends React.Component {
  static propTypes = {
    hotel: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      renaming: false
    }
  }

  componentDidMount() {
    this.ensureNewlyAddedTeamVisible()
    if (this.props.query) {
      this.toggleHightlightText(true)
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.query !== this.props.query) {
      this.toggleHightlightText(true)
    }
  }

  componentDidUpdate() {
    this.ensureNewlyAddedTeamVisible()
  }

  ensureNewlyAddedTeamVisible = () => {
    const { team, newTeamId } = this.props
    if (team.get('id') === newTeamId) {
      this.props.scrollIntoView(this.teamNode)
    }
  }

  handleSelectUser = ({ value }) => {
    const { dispatch, team, selectedHotelId } = this.props

    const teamId = team.get('id')
    const userId = value

    dispatch(addHotelUserToTeam(selectedHotelId, teamId, userId))
  }

  handleRemoveUser = (userId, ev) => {
    ev.preventDefault()
    const { dispatch, selectedHotelId, team } = this.props
    const teamId = team.get('id')

    dispatch(removeHotelUserFromTeam(selectedHotelId, teamId, userId))
  }

  handleRename = ev => {
    ev.preventDefault()

    const { dispatch, selectedHotelId, team } = this.props
    const teamId = team.get('id')
    const teamOldName = team.get('name')
    const updatedTeam = team.set('name', ev.target.value).toJS()

    this.setState({ renaming: true })
    dispatch(
      updateHotelTeam(selectedHotelId, teamId, updatedTeam, (statusCode, body) => {
        this.setState({ renaming: false })
        if (statusCode !== 200) {
          this.teamNameInput.value = teamOldName
          const errorUpdate = body && body.error
          const errMessage = errorUpdate && errorUpdate[0]
          alertify.error(errMessage || 'Error updating team name.')
        } else {
          alertify.success('Team name successfuly updated.')
          this.toggleHightlightText(true)
        }
      })
    )
  }

  handleRemove = ev => {
    ev.preventDefault()

    const { dispatch, selectedHotelId, team } = this.props
    const teamId = team.get('id')

    dispatch(
      deleteHotelTeam(selectedHotelId, teamId, (statusCode, body) => {
        if (statusCode !== 204) {
          alertify.alert(body.error)
        }
      })
    )
  }

  toggleHightlightText = value => {
    this.setState({ showHightlightText: value })
    if (!value) {
      this.teamNameInput.focus()
    }
  }

  render() {
    const { selectedAccountId, selectedHotelId, team, hotelUsers, query, newTeamId } = this.props
    const { showHightlightText } = this.state

    const teamMembers = team
      .get('users')
      .toSeq()
      .map(userId => hotelUsers.get(userId))
      .filter(user => user)
      .sortBy(user => _.toLower(HotelUtils.getStaffName(user)))

    return (
      <li
        ref={node => (this.teamNode = node)}
        className={`team ${team.get('id') === newTeamId ? 'highlight-new-team' : ''}`}
      >
        <h3>
          <button onClick={this.handleRemove} className="delete">
            <img src={'/icons/close.svg'} />
          </button>
          <div className="team-search-wrapper">
            <input
              type="text"
              size={(team.get('name') || ' ').length}
              ref={input => {
                this.teamNameInput = input
              }}
              disabled={this.state.renaming}
              defaultValue={team.get('name')}
              onFocus={() => this.toggleHightlightText(false)}
              onBlur={this.handleRename}
            />
            {query && showHightlightText && (
              <span className="text-wrapper" onClick={() => this.toggleHightlightText(false)}>
                <HighlightSearchMatches query={query} messageText={team.get('name')} />
              </span>
            )}
          </div>
        </h3>
        <ul className="users">
          {teamMembers.map(user => {
            return (
              <li key={user.get('id')} className="on-hover-bg-color">
                <Link
                  to={`/account/${selectedAccountId}/hotel/${selectedHotelId}/staff/${user.get(
                    'id'
                  )}`}
                  className="user"
                >
                  <span className="name">{`${user.get('first_name')} ${user.get(
                    'last_name'
                  )}`}</span>{' '}
                  <small>{user.get('title')}</small>
                </Link>
                <small>
                  <button
                    className="button button-secondary delete"
                    onClick={this.handleRemoveUser.bind(null, user.get('id'))}
                  >
                    Remove
                  </button>
                </small>
              </li>
            )
          })}
          <Select
            options={hotelUsers
              .toList()
              .filter(user => !team.get('users').contains(user.get('id')))
              .map(user => ({
                value: user.get('id'),
                label: `${user.get('first_name')} ${user.get('last_name')} (${user.get('title')})`
              }))
              .toJS()}
            onChange={this.handleSelectUser}
          />
        </ul>
      </li>
    )
  }
}

class HotelTeams extends React.Component {
  static propTypes = {
    hotel: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      query: ''
    }
  }

  componentDidMount() {
    const { dispatch, match } = this.props
    const { selectedHotelId } = match && match.params

    this.setState({ isLoading: true })
    dispatch(
      loadHotelTeams(selectedHotelId, () => {
        this.setState({ isLoading: false })
      })
    )

    dispatch(loadHotelStaff(selectedHotelId))

    this.addNewTeamIdParam(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.addNewTeamIdParam(nextProps)
  }

  addNewTeamIdParam = props => {
    const { location } = props
    const newTeamId = location && location.query && location.query.newTeamId
    this.setState({ newTeamId: newTeamId })
  }

  handleSearch = ev => {
    this.setState({ query: ev.target.value, newTeamId: null })
  }

  scrollElementIntoViewPort = teamDomNode => {
    setTimeout(() => teamDomNode.scrollIntoView({ behavior: 'smooth' }))
  }

  render() {
    const { dispatch, match, hotelTeams, hotelUsers } = this.props
    const { selectedAccountId, selectedHotelId } = match && match.params
    const { query, newTeamId, isLoading } = this.state

    const exp = new RegExp(RegExpUtils.filter(query), 'i')
    const teams = hotelTeams
      .toList()
      .filter(team => !query || (team.get('name') || '').search(exp) !== -1)
      .sortBy(team => _.toLower(team.get('name')))

    const sortedHotelUsers = hotelUsers.sortBy(user => _.toLower(HotelUtils.getStaffName(user)))

    return (
      <div className="settings-container team-settings display-flex-column">
        <Loader isLoading={isLoading} />
        <div className="header-items">
          <Link
            to={`/account/${selectedAccountId}/hotel/${selectedHotelId}/teams/new`}
            className="button pull-right"
          >
            Add a team
          </Link>
          <h1>
            Teams <span className="entity-count">({teams.size})</span>
          </h1>
          <input
            className="settings-search-input"
            type="search"
            onChange={this.handleSearch}
            value={query}
            placeholder="Search team"
          />
        </div>
        <ul>
          {teams.map(team => (
            <TeamItem
              key={team.get('id')}
              dispatch={dispatch}
              selectedAccountId={selectedAccountId}
              selectedHotelId={selectedHotelId}
              team={team}
              query={query}
              hotelUsers={sortedHotelUsers}
              newTeamId={newTeamId}
              scrollIntoView={this.scrollElementIntoViewPort}
            />
          ))}
        </ul>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  hotelTeams: state.hotelTeams,
  hotelUsers: state.hotelUsers
})

export default connect(mapStateToProps)(HotelTeams)
